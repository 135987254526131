import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { safePrefix } from "../utils";
import Header from "./Header";
import Footer from "./Footer";

export default class Body extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="google" content="notranslate" />
          <link
            href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i"
            rel="stylesheet"
          />
          <link
            rel="shortcut icon"
            href={safePrefix("images/favicon.ico")}
            type="image/x-icon"
          />
          >
          <link rel="stylesheet" href={safePrefix("assets/css/main.css")} />

          <title>Convey your Idea - Simply</title>

          <meta name="description" content="Visually Create Static Sites with ease. Target gatsbyjs & nextjs based sites."/>

          <link rel="canonical" href="https://www.simulify.com"/>

          <meta property="og:title" content="Visually Create Static Sites with ease"/>
          <meta property="og:description" content="Visually Create Static Sites with ease. Target gatsby & nextjs based sites."/>
          <meta property="og:image" content="https://images.ctfassets.net/hmomlg7opfne/175AJQlrP82n8CUoZs5TdJ/ea3ffe5005b81c08f45c3a67de26b308/undraw_character_drawing_k22e.svg"/>
          <meta property="og:image:width" content="878"/>
          <meta property="og:image:height" content="365"/>
          <meta property="og:url" content="https://www.simulify.com"/>
          <meta property="og:site_name" content="Convey your Idea - Simply"/>
          <meta property="og:type" content="website"/>

        </Helmet>
        <div
          id="page"
          class={
            "site palette-" +
            _.get(this.props, "pageContext.site.siteMetadata.palette")
          }
        >
          <Header {...this.props} />
          <main id="content" class="site-content">
            {this.props.children}
          </main>
          <Footer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
